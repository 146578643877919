body {
  background-color: #000000ed !important;
  color: white;
  max-height: fit-content;
  padding-left: 1rem;
  padding-right: 1rem;
}

.main-container {
  padding-left: 30px;
  height: 100vh;
}

/* iPhone Portrait */
@media only screen and (max-width: 480px) {
  .main-container {
    padding-left: 0;
  }

  html,
  body {
    max-width: 100%;
  }
}

/* iPad Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .main-container {
    padding-left: 0;
  }
  html,
  body {
    max-width: 100%;
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .main-container {
    padding-left: 0;
  }

  html,
  body {
    max-width: 100%;
  }
}
