.tags-container {
  position: relative;
  margin-bottom: 5rem;
  display: relative;
  align-items: center;
}

.general-container {
text-align: center;
}

.group-block {
  text-align: center;
}
.skill-tag {
  margin-left: 0.5rem;
  height: 3rem !important;
  font-size: 2rem !important;
  margin-top: 1rem;
}

.skill-tag.tech {
  color: #15616d !important;
  border: #15616d 1px solid !important;
}

.skill-tag.social {
  color: #4c6663 !important;
  border: #4c6663 1px solid !important;
}

.skill-tag.language {
    color: #6a6a6a !important;
    border: #6a6a6a 1px solid !important;
  }

.icon-tech {
  color: #15616d !important;
  font-size: 3rem !important;
  margin-top: 1rem;
}

.icon-social {
  color: #4c6663 !important;
  font-size: 3rem !important;
  margin-top: 1rem;
}

.icon-language {
  color: #6a6a6a !important;
  font-size: 3rem !important;
  margin-top: 1rem;
}
