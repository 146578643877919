#base-element {
  -webkit-transition: opacity 1.5s linear;
  -moz-transition: opacity 1.5s linear;
  -o-transition: opacity 1.5s linear;
  transition: opacity 1.5s linear;
  opacity: 0 !important;
}

#base-element.visible {
  opacity: 100% !important;
  -webkit-transition: opacity 0.8s linear;
  -moz-transition: opacity 0.8s linear;
  -o-transition: opacity 0.8s linear;
  transition: opacity 0.8s linear;
  display: inline-block !important;
  width: 100%;
}

.hidden {
  display: inline-block !important;
  opacity: 0;
}

@media only screen and (max-width: 480px) {

}
