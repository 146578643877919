.vertical-timeline-element-icon {
  box-shadow: 0 0 0 0 !important;
}

.vertical-timeline-element-subtitle {
  margin: 0.5rem !important
}

.pe-skill-tag.tech {
  color: #15616d !important;
  border: #15616d 1px solid !important;
}

.pe-skill-tag.social {
  color: #4c6663 !important;
  border: #4c6663 1px solid !important;
}

.pe-skill-tag {
  margin-left: 0.5rem;
  height: 1.5rem !important;
  font-size: 1rem !important;
  margin-top: 0.5rem;
}

.professional-experience-description {
  padding-top: 1rem;
}

.show-more-container {
  margin-top: 1rem;
}

.show-more-closed {
  height: 1rem;
  transition: all 0.5s ease-in;
}

.show-more-open {
  height: auto;
  transition: all 0.5s ease-in;
}

.expand-button {
  cursor: pointer;
}

.description {
  font-family: "Roboto", sans-serif;
  font-weight: 100 !important;
}

.description.hidden {
  max-height: 0;
  display: none;
}

.description.visible {
  max-height: 100%;
  display: block;
}


