* {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
}

.nav-item {
  position: absolute;
  color: #6a6a6a;
  bottom: 0.5rem;
  cursor: pointer;
  transition: font-size 0.4s;
}

.nav-item:hover {
  font-size: 1.2rem;
}

.divider {
  background-color: #212121 !important;
  margin-top: 0.3rem !important;
}

.relative-pos {
  position: relative;
}

.hovered {
  color: red;
  font-size: 10rem;
}

@media only screen and (max-width: 480px) {
  .nav-item {
    left: 17rem;
    transition: none;
  }

  .nav-item:hover {
    font-size: 1rem;
  }
}

/* iPhone */
@media only screen and (max-width: 480px) {
  .title {
    font-size: 1rem;
    margin-left: 0;
  }

  .nav-item {
    left: 17rem;
    transition: none;
  }

  .nav-item:hover {
    font-size: 1rem;
  }
}

