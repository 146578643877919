.keyword-tech {
  color: #69b8a0 !important;
}

.keyword-company {
  color: #acb869 !important;
}

.title-container {
  text-align: center;
}

.title-aboutme {
font-size: 5rem;
color: #808080;
}

.link {
  color: #808080;
  text-decoration: underline;

}

.aboutme-footnote {
  padding-top: 3rem;
}

.self-photo {
  width: 20rem;
  border-radius: 50%;
  margin-bottom: 5rem;
}

.grid {
  margin-top: 5rem;
  display: grid;
}
.grid.grid-center {
  justify-content: center;
  align-items: center;
}

p {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  text-align: justify;
  margin-right: 5rem;
}


.date-tag {
  color: #EEEEEE
}

@media only screen and (max-width: 480px) {
  .self-photo {
    width: 10rem;
    border-radius: 50%;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
    margin-right: 0rem;
  }

.title-aboutme {
  font-size: 3rem;
  }

  .grid {
    margin-top: 0;
    display: grid;
  }
}
