.arrow-icon {
  color: #6a6a6a;
  opacity: 0.5;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

}

.arrow-icon:hover {
  color: #afafaf;
  font-size: 3.2rem !important;
  transition: all 0.2s ease-in-out;
}

.centered-circle {
  display: inline-flex;
  width: 95%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  text-align: center;
  transition: height 2s;
}

.circle-tag {
  font-size: 3rem;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 10px;
  text-align: center;
  transition: height 2s;
}

@media only screen and (max-width: 480px) {

  .circle-tag {
    font-size: 1.5rem;
  }

  .arrow-icon {
    color: #6a6a6a;
    opacity: 0.5;
    font-size: 2rem!important;
    margin-top: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  .arrow-icon:hover {
    color: #afafaf;
    font-size: 2rem !important;
    transition: none;
  }
}