.header-title {
  color: #6a6a6a;
  font-size: 1.5rem;
  cursor: pointer;

}
 
p {
  color: #808080;
  font-size: 1.5rem;
  margin-left: 2rem;
}

@media only screen and (max-width: 480px) {
  .title {
    color: #6a6a6a;
    font-size: 1.5rem;
    margin-left: 0;
  }

  p {
    font-size: 1.5rem;
    margin-left: 0;
  }
}

.dark-blue {
  color: #15616d !important;

}

.light-blue {
  color: #4c6663 !important;

}