.centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  text-align: center;
  transition: height 2s;
}

.major-label-container {
    padding-top: 2rem;
}

.major-label {
    color: #6a6a6a;
}

.label {
    font-size: 3rem;
    font-weight: 100;
    color: #6a6a6a;
}


@media screen and (min-width: 1600px) {
  .major-label {
    font-size: 5rem;
    font-weight: 100;
  }
}

@media screen and (max-width: 1600px) {
    .major-label {
      font-size: 4rem;
      font-weight: 100;
    }

    .label {
        font-size: 2rem;
        font-weight: 100;
        color: #6a6a6a;
    }
  }

@media screen and (max-width: 1288px) {
    .major-label {
      font-size: 3rem;
      font-weight: 100;
    }
  }

@media screen and (max-width: 770px) {
    .major-label {
      font-size: 2rem;
      font-weight: 100;
    }

    .label {
        font-size: 1rem;
        font-weight: 100;
        color: #6a6a6a;
    }
  }

@media screen and (max-width: 400px) {
    .centered {
        width: 90%;
    }

    .major-label {
      font-size: 2rem;
      font-weight: 100;
    }

    .label {
        font-size: 1rem;
        font-weight: 100;
        color: #6a6a6a;
    }
  }
